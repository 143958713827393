import React from "react"
import styled from "styled-components";
import * as Web3 from "web3-eth";
import Web3Utils from "web3-utils";
import { abi } from '../utils/abi';

const styles = {
    container: {
        fontFamily: 'Lato, Arial',
        fontSize: '24px',
        width: '100%',
        height: '100vh',
        color: 'white',
        boxSizing: 'border-box',
        background: 'linear-gradient(317deg, rgba(131,103,95,1) 0%, rgba(69,60,57,1) 19%, rgba(31,27,26,1) 100%)',
    },
    imageStyle: {
        objectFit: 'cover',
        height: '50px',
    },
    rebellionText: {
        marginLeft: '15px',
        fontWeight: '600',
    },
    daoText: {
        fontSize: '14px',
        marginLeft: '15px',
        color: '#ff9800',
    },
    innerWindow: {
        padding: '20px',
        display: 'flex',
        alignItems: 'center',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    navButtons: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 20px',
    },
    navButton: {
        margin: '10px 20px',
        fontSize: '14px',
        display: 'flex',
    },
    navSecondary: {
        border: '1px solid #FFF',
        borderRadius: '20px',
        padding: '15px',
        fontSize: '14px',
    },
    tokenIcon: {
        objectFit: 'cover',
        height: '20px',
        marginLeft: '5px',
    },
    mainContent: {
        marginTop: '70px',
        width: '100%',
        padding: '50px 50px 0',
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'space-between',
    },
    leftContent: {
        flexBasis: '50%',
        padding: '50px',
    },
    primaryText: {
        fontSize: '60px',
        fontWeight: '600',
    },
    secondaryText: {
        fontSize: '24px',
        marginTop: '50px',
        color: '#a39b99',
    },
    buttons: {
        display: 'flex',
        marginTop: '50px',
    },
    buttonSecondary: {
        border: '1px solid #FFF',
        borderRadius: '30px',
        padding: '20px',
        fontSize: '20px',
    },
    buttonPrimary: {
        padding: '20px',
        backgroundColor: '#ff9800',
        borderRadius: '30px',
        fontSize: '20px',
        fontWeight: '600',
        marginLeft: '50px',
        color: '#262423',
    },
    rightContent: {
        flexBasis: '50%',
        padding: '10px 50px 50px',
        display: 'flex',
        justifyContent: 'center',
    },
    primaryImage: {
        objectFit: 'cover',
        height: '400px',
    },
    bottomInfo: {
        borderRadius: '30px',
        boxSizing: 'border-box',
        padding: '30px',
        margin: '20px',
        alignSelf: 'flex-end',
        display: 'flex',
        backgroundColor: 'rgba(0,0,0,.1)',
    },
    statSection: {
        marginRight: '80px',
    },
    statTitle: {
        fontSize: '14px',
    },
    statInfo: {
        fontWeight: '600',
        fontSize: '20px',
        marginTop: '5px',
    },
    rightInner: {
        borderRadius: '50%',
        width: '500px',
        height: '500px',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
}

const PrimaryButton = styled.div`
transition: all 0.3s ease 0s;
:hover {
    background-color: #c77700 !important;
}
`

const SecondaryButton = styled.div`
transition: all 0.3s ease 0s;
:hover {
    background-color: #FFF !important;
    border-color: #FFF !important;
    color: #333 !important;
}
`

const NavButton = styled.div`
transition: all 0.3s ease 0s;
:hover {
    color: #999 !important;
}
`

class MainPage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            totalSupply: null,
            rebPrice: null,
            citizens: null
        }
    }

    componentDidMount = async() => {
        const web3Instance = new Web3('https://polygon-rpc.com/')
        let myContract = new web3Instance.Contract(abi, "0x561f7ae92a00c73dc48282d384e0a4fc1f4bc305", { gasLimit: "15000000" })
        let totalSupply = await myContract.methods.totalSupply().call();
        totalSupply = Number(Web3Utils.fromWei(totalSupply, 'ether')).toFixed(2)
        this.setState({
            totalSupply: totalSupply
        })

        fetch(`https://rebellionbase.com/api/v0/stats`, {
            method: "GET"
        }).then(res => res.json())
            .then(async(res) => {
                if (res.success) {
                    this.setState({rebPrice: res.stats.reb_price, citizens: res.citizens})
                } else {

                }
            })
            .catch(err => {

            })
    }

      render () {
            return (
                  <div style={styles.container}>
                      <div style={styles.header}>
                          <a href={'/'}>
                              <div style={styles.innerWindow}>
                                  <img style={styles.imageStyle} src={'https://nanobash.s3.us-west-2.amazonaws.com/fist2.png'} />
                                  <div>
                                    <div style={styles.rebellionText}>
                                        {`Rebellion`}
                                    </div>
                                    <div style={styles.daoText}>
                                        {`DAO`}
                                    </div>
                                  </div>
                              </div>
                          </a>
                          <div style={styles.navButtons}>
                              <NavButton style={styles.navButton}>
                                  <a target={"_blank"} href={'https://quickswap.exchange/#/swap?outputCurrency=0x561f7ae92a00c73dc48282d384e0a4fc1f4bc305'}>
                                      Buy REB
                                  </a>
                              </NavButton>
                              <NavButton style={styles.navButton}>
                                  <a target={"_blank"} href={'https://docs.rebelliondao.com/'}>
                                      Docs
                                  </a>
                              </NavButton>

                              <NavButton style={{...styles.navButton, marginRight: '50px'}}>
                                  <a target={"_blank"} href={'https://discord.gg/a4egATVu8r'}>
                                      Discord
                                  </a>
                              </NavButton>
                              <a href={'https://rebellionbase.com/verify'} target={"_blank"}>
                                  <SecondaryButton style={styles.navSecondary}>
                                      Become a Citizen
                                  </SecondaryButton>
                              </a>
                          </div>
                      </div>
                      <div style={styles.mainContent}>
                          <div style={styles.leftContent}>
                              <div style={styles.primaryText}>
                                  Join the Rebellion DAO
                              </div>
                              <div style={styles.secondaryText}>
                                  Mint Rebel NFTs - Collect Free Daily Income - Become a Citizen - Vote On The Future - Contribute To The Community
                              </div>
                              <div style={styles.buttons}>
                                  <a href={'https://docs.rebelliondao.com/'} target={"_blank"}>
                                      <SecondaryButton style={styles.buttonSecondary}>
                                          Learn More
                                      </SecondaryButton>
                                  </a>
                                  <a href={'https://rebellionbase.com/'} target={"_blank"}>
                                      <PrimaryButton style={styles.buttonPrimary}>
                                          Enter App
                                      </PrimaryButton>
                                  </a>
                              </div>
                          </div>
                          <div style={styles.rightContent}>
                              <div style={styles.rightInner}>
                                  <img style={styles.primaryImage} src={'https://nanobash.s3.us-west-2.amazonaws.com/fist2.png'} />
                              </div>
                          </div>
                      </div>
                      <div style={styles.bottomInfo}>
                          <div style={styles.statSection}>
                              <div style={styles.statTitle}>
                                  Citizens
                              </div>
                              <div style={styles.statInfo}>
                                  {this.state.citizens ?? `---`}
                              </div>
                          </div>
                          <div style={styles.statSection}>
                              <div style={styles.statTitle}>
                                  REB Tokens
                              </div>
                              <div style={styles.statInfo}>
                                  {this.state.totalSupply ?? `---`}
                              </div>
                          </div>
                          <div style={styles.statSection}>
                              <div style={styles.statTitle}>
                                  REB Price
                              </div>
                              <div style={styles.statInfo}>
                                  {this.state.rebPrice ? `$${this.state.rebPrice}` : `---`}
                              </div>
                          </div>
                          <div style={styles.statSection}>
                              <div style={styles.statTitle}>
                                  Market Cap
                              </div>
                              <div style={styles.statInfo}>
                                  {this.state.rebPrice && this.state.totalSupply ? `$${(parseFloat(this.state.rebPrice) * parseFloat(this.state.totalSupply)).toFixed(2)}` : `---`}
                              </div>
                          </div>
                      </div>

                  </div>
            );
      }
}

export default MainPage
